import React, { useEffect, useState } from "react";
import RenderFields from "./RenderFields";
import { Card, CardBody, CardSubtitle, CardTitle } from "reactstrap";

import {
  getsamplelabcodeApi,
  sampleInwardDetailsGetAPI,
  testReportDetailsApi,
} from "../../services/api";

import {
  GetTenantDetails,
  postDataFromApi,
} from "../../services/commonServices";
import ActionOptionsTable from "./ActionOptionsTable";
import {
  getFormatedDate,
  getSelectedOptionName,
} from "../../services/commonFunction";
import { assignmentPageHandleAction } from "./commonHandlerFunction/GroupAssignmentFunctions";
import { InwardPageHandleAction } from "./commonHandlerFunction/sampleInwardHandlerFunctions";
import { sampleVerificationHandler } from "./commonHandlerFunction/sampleVerificationHandlerFunctions";
import PropTypes from "prop-types";
import ModalInward from "./commonModalForms/modalInward";
import SampleVerificationDetals from "./commonModalForms/SampleVerificationDetals";
const RenderTableSection = ({
  section,
  sectionIndex,
  formData,
  handleFieldChange,
  addRow,
  deleteRow,
  deleteColumn,
  formErrors,
  setFormData,
  popupMessages,
  pageType,
  action,
  masterOptions,
  saveClicked,
  setSaveClicked,
  setTableData,
  tableData,
  moduleType,
  setSimpaleInwardResponse,
  simpleInwardResponse,
  groupDefaultValue,
  testMemoId,
  getVerificationDetails,
  getSampleIdsMasterData,
  setIsOverlayLoader,
  isOverlayLoader,
}) => {
  const [popupIndex, setPopupIndex] = useState(-1);
  const [isDisplayNewAddOption, setIsDisplayNewAddOption] = useState(true);

  const [popupOpenAssignment, setPopupOpenAssignment] = useState(false);
  const [isBtnclicked, setIsBtnClicked] = useState(false);
  const [sampleDetails, setSampleDetails] = useState([]);
  const [isViewOpen, setIsViewOpen] = useState(false);
  const [viewTableData, setViewTableData] = useState([]);

  let VerificationSaveAction = [
    {
      icon: "bi bi-floppy-fill",
      text: "Save",
    },
  ];
  let EditAction = [
    {
      icon: "bi bi-floppy-fill",
      text: "Save",
    },
    {
      icon: "bi bi-x-circle-fill",
      text: "Cancel",
    },
  ];
  let MainAction = [
    {
      icon: "bi bi-pencil-square",
      text: "Edit",
    },
    {
      icon: "bi bi-trash3-fill",
      text: "Delete",
    },
  ];
  let saveAction = [
    {
      icon: "bi bi-floppy-fill",
      text: "Save",
    },
  ];
  let plusAction = [
    {
      icon: "bi bi-plus-circle-fill",
      text: "Save",
    },
  ];

  if (pageType === "assignment") {
    MainAction.splice(0, 1);
  }

  if (moduleType === "sampleverification") {
    MainAction.splice(1, 1);
    MainAction.push({
      icon: "bi bi-eye",
      text: "View",
    });
  }

  const [simpleInwardId, setSimpleInwardId] = useState("");
  const [editableIndex, setEditableIndex] = useState("");
  const [updatedMasterOptions, setUpdatedMasterOptions] = useState([]);
  const [actualMasterOptions, setActualMasterOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const onSingleFieldChange = (
    sectionIndex,
    fieldName,
    value,
    type = "",
    isChecked = ""
  ) => {
    if (moduleType === "sampleverification") {
      let spName = fieldName.split("_");

      if (spName[spName.length - 1] === "0") {
        const updatedFormData = { ...formData };
        if (!updatedFormData[sectionIndex]) {
          updatedFormData[sectionIndex] = {};
        }
        updatedFormData[sectionIndex][fieldName] = value;
        let actualFieldName = fieldName.replace(
          "_" + spName[spName.length - 1],
          ""
        );
        tableData.map((row, rowIndex) => {
          let newFieldName = actualFieldName + "_" + rowIndex;
          if (!updatedFormData[sectionIndex][newFieldName]) {
            updatedFormData[sectionIndex][newFieldName] = value;
          }
        });

        setFormData(updatedFormData);
        return;
      }
    }
    handleFieldChange(sectionIndex, fieldName, value, type, isChecked);
  };

  useEffect(() => {
    if (pageType === "assignment" && groupDefaultValue.length > 0) {
      const updatedFormData = { ...formData };
      const fieldName = "smpl_set_basisjson_" + tableData.length;
      if (!updatedFormData[sectionIndex]) {
        updatedFormData[sectionIndex] = {};
      }
      let groupFilterData = groupDefaultValue.filter((singleData) => {
        return singleData.name === "ADB";
      });
      if (groupFilterData.length > 0) {
        updatedFormData[sectionIndex][fieldName] = [groupFilterData[0].id];
        setFormData(updatedFormData);
      }
    }
  }, [groupDefaultValue, tableData]);

  useEffect(() => {
    setSimpleInwardId(formData[1]?.sampleInwardIdMain);
    setTimeout(() => {
      if (formData[1]?.sampleInwardIdMain) {
        getInwardTabledata(formData[1]?.sampleInwardIdMain);
      }
    }, 1000);
  }, [formData[1]?.sampleInwardIdMain]);
  useEffect(() => {
    setTimeout(() => {
      if (moduleType === "sampleverification" && testMemoId && !editableIndex) {
        getSampleLabCodeDetails(testMemoId);
      }
    }, 1000);
  }, [testMemoId]);
  useEffect(() => {
    if (testMemoId && !editableIndex) {
      getVerificationDetails(formData[0].sv_id, 1);
      setTimeout(() => {
        getSampleLabCodeDetails(testMemoId);
      }, 1000);
    }
  }, [editableIndex]);
  useEffect(() => {
    setTimeout(() => {
      getSampleOptionData();
    }, 1000);
  }, [masterOptions, selectedOptions]);
  const getTestReportDetails = async (id, scope_type) => {
    try {
      const bodyToPass = {
        test_memo_id: id,
        context: scope_type,
      };
      let res = await postDataFromApi(testReportDetailsApi, bodyToPass);
      if (res?.data?.status === 200) {
        const actualResponse = res.data.data;
        let allsamples = sampleDetails;
        actualResponse.sample_sets.map((singleSet) => {
          singleSet.samples.map((singleSample) => {
            let filteredData = allsamples.filter((singleStoreSample) => {
              return (
                singleStoreSample.sample_detail_id ==
                singleSample.sample_detail_id
              );
            });
            if (filteredData.length === 0) {
              allsamples.push(singleSample);
            }
          });
        });
        setSampleDetails(allsamples);
      }
    } catch (error) {
      console.log("err", error);
    }
  };
  const getSampleOptionData = () => {
    if (actualMasterOptions.length === 0) {
      setActualMasterOptions(masterOptions);
    }
    let simplaMasterData = actualMasterOptions?.find((model, index) => {
      if (model.model === "smpl_set_smpljson") {
        return model;
      }
    });
    let newMasterOptions = masterOptions;
    const InwardUnits = formData[1]?.jrf_commodity_detail?.cmd_unit || [];
    let unitoptions = [];
    InwardUnits.map((singleOpt) => {
      unitoptions.push({
        name: singleOpt.cu_name,
        id: singleOpt.cu_symbol,
      });
    });
    if (InwardUnits.length === 0) {
      unitoptions.push({
        name: "GM",
        id: "gm",
      });
    }
    newMasterOptions.push({
      model: "smpl_detail_smpl_qty_unit",
      data: unitoptions,
    });
    if (simplaMasterData) {
      let notSelectedOptions = simplaMasterData?.data.filter((simpleId) => {
        return !selectedOptions.includes(simpleId.name);
      });
      const bodyToPass = {
        model: "smpl_set_smpljson",
        data: notSelectedOptions,
      };
      let isExists = false;
      let filterData = newMasterOptions.filter((model) => {
        if (model.model === "smpl_set_smpljson") {
          model.data = notSelectedOptions;
          isExists = true;
        }
        return true;
      });
      if (isExists) {
        newMasterOptions = filterData;
      } else {
        newMasterOptions.push(bodyToPass);
      }

      if (notSelectedOptions.length === 0) {
        setIsDisplayNewAddOption(false);
      } else {
        setIsDisplayNewAddOption(true);
      }
    }
    setUpdatedMasterOptions(newMasterOptions);
  };
  const onActionHandleClick = async (actionSelected) => {
    if (pageType === "assignment") {
      assignmentPageHandleAction(
        actionSelected,
        editableIndex,
        tableData,
        simpleInwardId,
        formData,
        setSaveClicked,
        getInwardTabledata,
        setPopupIndex,
        setEditableIndex,
        popupIndex,
        section,
        "",
        updatedMasterOptions,
        getSampleIdsMasterData
      );
    } else if (moduleType === "sampleverification") {
      sampleVerificationHandler(
        actionSelected,
        editableIndex,
        tableData,
        formData[0]?.fk_testmemo_id,
        formData,
        section,
        setSaveClicked,
        setPopupIndex,
        setEditableIndex,
        popupIndex,
        "",
        setIsOverlayLoader
      );
    } else {
      InwardPageHandleAction(
        actionSelected,
        editableIndex,
        tableData,
        simpleInwardId,
        formData,
        section,
        setSaveClicked,
        setEditableIndex,
        getInwardTabledata,
        setPopupIndex,
        popupIndex,
        setPopupOpenAssignment,
        setIsBtnClicked,
        setIsOverlayLoader
      );
    }
  };

  const getInwardTabledata = async (simpleId) => {
    setIsOverlayLoader(true);
    let payload = {
      smpl_inwrd_id: simpleId,
    };
    let res = await postDataFromApi(sampleInwardDetailsGetAPI, payload);
    if (res.data.status === 200) {
      const updatedFormData = { ...formData };
      if (tableData.length === 0) {
        section.rows.forEach((row) => {
          row.forEach((columnName) => {
            if (columnName.name !== "sample_id") {
              if (columnName.name != "smpl_detail_smpl_qty_unit") {
                if (columnName.name !== "smpl_detail_smpl_qty") {
                  let fieldNamerm = `${columnName.name}_0`;
                  let newfieldNamerm = `${columnName.name}_${res.data.data.sample_detail_data.length}`;
                  updatedFormData[sectionIndex][newfieldNamerm] =
                    updatedFormData[sectionIndex][fieldNamerm];

                  if (updatedFormData[sectionIndex][fieldNamerm]) {
                    delete updatedFormData[sectionIndex][fieldNamerm];
                  }
                } else {
                  let fieldNamerm = `${columnName.name}_0`;
                  let fieldNamermunit = `${columnName.name}_unit_0`;
                  let newfieldNamerm = `${columnName.name}_${res.data.data.sample_detail_data.length}`;
                  let newfieldNamermunit = `${columnName.name}_unit_${res.data.data.sample_detail_data.length}`;
                  updatedFormData[sectionIndex][newfieldNamerm] =
                    updatedFormData[sectionIndex][fieldNamerm];
                  updatedFormData[sectionIndex][newfieldNamermunit] =
                    updatedFormData[sectionIndex][fieldNamermunit];

                  if (updatedFormData[sectionIndex][fieldNamerm]) {
                    delete updatedFormData[sectionIndex][fieldNamerm];
                    delete updatedFormData[sectionIndex][fieldNamermunit];
                  }
                }
              }
            }
          });
          if (
            updatedFormData[sectionIndex]["smpl_detail_smpl_condtion_0"] ===
            "Raw and Powdered Sample"
          ) {
            ["smpl_detail_smpl_pwd_qty_unit", "smpl_detail_smpl_pwd_qty"].map(
              (columnName) => {
                let fieldNamerm = `${columnName.name}_0`;
                let fieldNamermunit = `${columnName.name}_unit_0`;
                let newfieldNamerm = `${columnName.name}_${res.data.data.sample_detail_data.length}`;
                let newfieldNamermunit = `${columnName.name}_unit_${res.data.data.sample_detail_data.length}`;
                updatedFormData[sectionIndex][newfieldNamerm] =
                  updatedFormData[sectionIndex][fieldNamerm];
                updatedFormData[sectionIndex][newfieldNamermunit] =
                  updatedFormData[sectionIndex][fieldNamermunit];

                if (updatedFormData[sectionIndex][fieldNamerm]) {
                  delete updatedFormData[sectionIndex][fieldNamerm];
                  delete updatedFormData[sectionIndex][fieldNamermunit];
                }
              }
            );
          }
        });
        if (
          updatedFormData[sectionIndex]["smpl_detail_smpl_condtion_0"] ==
          "Raw and Powdered Sample"
        ) {
          ["smpl_detail_smpl_pwd_qty_unit", "smpl_detail_smpl_pwd_qty"].map(
            (columnName) => {
              let fieldNamerm = `${columnName}_0`;
              let newfieldNamerm = `${columnName}_${res.data.data.sample_detail_data.length}`;
              updatedFormData[sectionIndex][newfieldNamerm] =
                updatedFormData[sectionIndex][fieldNamerm];

              if (updatedFormData[sectionIndex][fieldNamerm]) {
                delete updatedFormData[sectionIndex][fieldNamerm];
              }
            }
          );
        }
      } else {
        tableData.map((singleInwardData, i) => {
          section.rows.forEach((row) => {
            row.forEach((columnName) => {
              if (columnName.name !== "sample_id") {
                if (columnName.name != "smpl_detail_smpl_qty_unit") {
                  if (columnName.name === "smpl_detail_smpl_qty") {
                    let fieldNamerm = `${columnName.name}_${i}`;
                    let fieldNamermunit = `${columnName.name}_unit_${i}`;
                    if (updatedFormData[sectionIndex][fieldNamerm]) {
                      delete updatedFormData[sectionIndex][fieldNamerm];
                      delete updatedFormData[sectionIndex][fieldNamermunit];
                    }
                    if (tableData.length - 1 === i) {
                      fieldNamerm = `${columnName.name}_${tableData.length}`;
                      fieldNamermunit = `${columnName.name}_unit_${tableData.length}`;
                      if (
                        tableData.length !==
                        res.data.data.sample_detail_data.length
                      ) {
                        let newfieldNamermunit = `${columnName.name}_unit_${res.data.data.sample_detail_data.length}`;
                        let newfieldNamerm = `${columnName.name}_${res.data.data.sample_detail_data.length}`;
                        updatedFormData[sectionIndex][newfieldNamerm] =
                          updatedFormData[sectionIndex][fieldNamerm];
                        updatedFormData[sectionIndex][newfieldNamermunit] =
                          updatedFormData[sectionIndex][fieldNamermunit];
                      }

                      if (updatedFormData[sectionIndex][fieldNamerm]) {
                        delete updatedFormData[sectionIndex][fieldNamerm];
                        delete updatedFormData[sectionIndex][fieldNamermunit];
                      }
                    }
                  } else {
                    let fieldNamerm = `${columnName.name}_${i}`;
                    if (updatedFormData[sectionIndex][fieldNamerm]) {
                      delete updatedFormData[sectionIndex][fieldNamerm];
                    }
                    if (tableData.length - 1 === i) {
                      fieldNamerm = `${columnName.name}_${tableData.length}`;
                      if (
                        tableData.length !==
                        res.data.data.sample_detail_data.length
                      ) {
                        let newfieldNamerm = `${columnName.name}_${res.data.data.sample_detail_data.length}`;
                        updatedFormData[sectionIndex][newfieldNamerm] =
                          updatedFormData[sectionIndex][fieldNamerm];
                      }

                      if (updatedFormData[sectionIndex][fieldNamerm]) {
                        delete updatedFormData[sectionIndex][fieldNamerm];
                      }
                    }
                  }
                }
              }
            });
          });
          if (
            updatedFormData[sectionIndex]["smpl_detail_smpl_condtion_" + i] ==
            "Raw and Powdered Sample"
          ) {
            ["smpl_detail_smpl_pwd_qty_unit", "smpl_detail_smpl_pwd_qty"].map(
              (columnName) => {
                let fieldNamerm = `${columnName}_${i}`;
                if (updatedFormData[sectionIndex][fieldNamerm]) {
                  delete updatedFormData[sectionIndex][fieldNamerm];
                }
                if (tableData.length - 1 === i) {
                  fieldNamerm = `${columnName}_${tableData.length}`;
                  if (
                    tableData.length !== res.data.data.sample_detail_data.length
                  ) {
                    let newfieldNamerm = `${columnName}_${res.data.data.sample_detail_data.length}`;
                    updatedFormData[sectionIndex][newfieldNamerm] =
                      updatedFormData[sectionIndex][fieldNamerm];
                  }

                  if (updatedFormData[sectionIndex][fieldNamerm]) {
                    delete updatedFormData[sectionIndex][fieldNamerm];
                  }
                }
              }
            );
          }
        });
      }

      if (pageType === "inward") {
        res.data.data.sample_detail_data.forEach((singleInwardData, i) => {
          if (!updatedFormData[sectionIndex]) {
            updatedFormData[sectionIndex] = {};
          }
          section.rows.forEach((row) => {
            row.forEach((columnName) => {
              if (columnName.name !== "smpl_detail_smpl_qty_unit") {
                const fieldName = `${columnName.name}_${i}`;
                const value =
                  columnName.name === "sample_id"
                    ? singleInwardData["smpl_detail_smpl_id"]
                    : singleInwardData[columnName.name];
                if (columnName.name === "smpl_detail_smpl_qty") {
                  var spValue = value.split(" / ");
                  updatedFormData[sectionIndex][fieldName] = spValue[0];
                  updatedFormData[sectionIndex][
                    `${columnName.name}_unit_${i}`
                  ] = spValue.length > 1 ? spValue[1] : "";
                } else {
                  if (columnName.name === "smpl_detail_dos") {
                    updatedFormData[sectionIndex][fieldName] = value;
                  } else {
                    updatedFormData[sectionIndex][fieldName] = value;
                  }
                }
              }
            });
          });
          if (
            singleInwardData.smpl_detail_smpl_condtion ===
            "Raw and Powdered Sample"
          ) {
            ["smpl_detail_smpl_pwd_qty_unit", "smpl_detail_smpl_pwd_qty"].map(
              (columnName) => {
                const fieldName = `${columnName}_${i}`;
                const value = singleInwardData[columnName];
                updatedFormData[sectionIndex][fieldName] = value;
              }
            );
          }
        });
        setTableData(res.data.data.sample_detail_data);
      } else if (pageType === "assignment") {
        let selectedSimpleIds = [];
        res.data.data.sample_set_data.forEach((singleInwardData, i) => {
          singleInwardData.smpl_set_smpljson.map((simpleId) => {
            selectedSimpleIds.push(simpleId);
          });

          if (!updatedFormData[sectionIndex]) {
            updatedFormData[sectionIndex] = {};
          }
          section.rows.forEach((row) => {
            row.forEach((columnName) => {
              const fieldName = `${columnName.name}_${i}`;
              const value = singleInwardData[columnName.name];
              updatedFormData[sectionIndex][fieldName] = value;
            });
          });
        });
        setSelectedOptions(selectedSimpleIds);
        setTableData(res.data.data.sample_set_data);
      }
      setSimpleInwardId(res.data.data.smpl_inwrd_id);
      updatedFormData[0]["smpl_status"] = res.data.data.smpl_status;
      updatedFormData[0]["smpl_inwrd_No"] = res.data.data.smpl_inward_number;
      updatedFormData[0]["smpl_detail_dos"] = res.data.data.smpl_dos;
      updatedFormData[0]["inward_msfm_number"] =
        res.data.data.inward_msfm_number;
      updatedFormData[0]["smpl_detail_recpt_mode"] =
        res.data.data.smpl_receipt_mode;
      updatedFormData[1][
        "smpl_detail_dos_" + res.data.data.sample_detail_data.length
      ] = res.data.data.smpl_dos;
      updatedFormData[1][
        "smpl_detail_recpt_mode_" + res.data.data.sample_detail_data.length
      ] = res.data.data.smpl_receipt_mode;
      setFormData(updatedFormData);
    }
    setIsOverlayLoader(false);
  };

  const getSampleLabCodeDetails = async (testMemoId) => {
    let payload = {
      test_memo_id: testMemoId,
    };
    var count = 0;
    let res = await postDataFromApi(getsamplelabcodeApi, payload);
    if (res.data.status === 200) {
      let updatedFormData = { ...formData };
      let SPTableData = [];
      res.data.data.forEach((singleData, i) => {
        if (!updatedFormData[sectionIndex]) {
          updatedFormData[sectionIndex] = {};
        }
        let multipleVerificationValue = 0;
        let verificationData = getVerificationDataFunc(
          singleData,
          SPTableData,
          updatedFormData,
          count
        );
        SPTableData = verificationData.SPTableData;
        updatedFormData = verificationData.updatedFormData;
        count = verificationData.count;
        // if (singleData.smpl_detail_is_raw_and_powdered) {
        //   let dupverificationData = getVerificationDataFunc(
        //     singleData,
        //     SPTableData,
        //     updatedFormData,
        //     count,
        //     1
        //   );
        //   SPTableData = dupverificationData.SPTableData;
        //   updatedFormData = dupverificationData.updatedFormData;
        //   count = dupverificationData.count;
        // }
      });
      setFormData(updatedFormData);
      setTableData(SPTableData);
    }
  };

  const getVerificationDataFunc = (
    singleData,
    SPTableData,
    updatedFormData,
    count,
    isDuplicate
  ) => {
    singleData?.["sample_details"].map((smlDetails, spID) => {
      isDuplicate = "";
      const ExistsData = checkVerificationDataExists(
        smlDetails["sp_lab_smplcode"],
        isDuplicate
      );
      if (ExistsData.length > 0) {
        updatedFormData[sectionIndex]["svd_id_" + count] =
          ExistsData[0]["svd_id"];
      }
      section.rows.forEach((row) => {
        row.forEach((columnName) => {
          const fieldName = `${columnName.name}_${count}`;
          let value;
          if (ExistsData.length > 0) {
            if (columnName.name === "sp_lab_smplcode") {
              value = ExistsData[0].svd_smpllabcode;
            } else if (columnName.name === "sample_quantity") {
              value = ExistsData[0].svd_smplweight;
            } else {
              value = ExistsData[0][columnName.name];
            }
          } else {
            if (updatedFormData[sectionIndex][fieldName]) {
              value = updatedFormData[sectionIndex][fieldName];
            } else {
              value = smlDetails[columnName.name]
                ? smlDetails[columnName.name]
                : "";
            }
          }
          if (
            ["svd_stdsizeofsmpl", "svd_abovesize", "svd_belowsize"].includes(
              columnName.name
            )
          ) {
            let unitValue = "";
            if (value) {
              value = value.toString();
              var spUnitValue = value.split(" ");
              unitValue = spUnitValue.length > 1 ? spUnitValue[1] : "";
              value = spUnitValue[0];
            }

            if (
              updatedFormData[sectionIndex][columnName.name + "_unit_" + count]
            ) {
              updatedFormData[sectionIndex][
                columnName.name + "_unit_" + count
              ] =
                updatedFormData[sectionIndex][
                  columnName.name + "_unit_" + count
                ];
            } else {
              updatedFormData[sectionIndex][
                columnName.name + "_unit_" + count
              ] = unitValue;
            }
            if (updatedFormData[sectionIndex][fieldName]) {
              updatedFormData[sectionIndex][fieldName] =
                updatedFormData[sectionIndex][fieldName];
            } else {
              updatedFormData[sectionIndex][fieldName] = value;
            }
          } else {
            if (isDuplicate && columnName.name === "sample_quantity") {
              updatedFormData[sectionIndex][fieldName] =
                smlDetails["smpl_detail_smpl_pwd_qty"] +
                " " +
                smlDetails["smpl_detail_smpl_pwd_qty_unit"];
            } else {
              updatedFormData[sectionIndex][fieldName] = value;
            }
          }
        });
      });
      SPTableData.push(smlDetails);
      isDuplicate = smlDetails.smpl_detail_is_raw_and_powdered;
      if (isDuplicate) {
        count++;
        const ExistsData = checkVerificationDataExists(
          smlDetails["sp_lab_smplcode"],
          isDuplicate
        );
        if (ExistsData.length > 0) {
          updatedFormData[sectionIndex]["svd_id_" + count] =
            ExistsData[0]["svd_id"];
        }
        section.rows.forEach((row) => {
          row.forEach((columnName) => {
            const fieldName = `${columnName.name}_${count}`;
            let value;
            if (ExistsData.length > 0) {
              if (columnName.name === "sp_lab_smplcode") {
                value = ExistsData[0].svd_smpllabcode;
              } else if (columnName.name === "sample_quantity") {
                value = ExistsData[0].svd_smplweight;
              } else {
                value = ExistsData[0][columnName.name];
              }
            } else {
              if (updatedFormData[sectionIndex][fieldName]) {
                value = updatedFormData[sectionIndex][fieldName];
              } else {
                value = smlDetails[columnName.name]
                  ? smlDetails[columnName.name]
                  : "";
              }
            }
            if (
              ["svd_stdsizeofsmpl", "svd_abovesize", "svd_belowsize"].includes(
                columnName.name
              )
            ) {
              let unitValue = "";
              if (value) {
                value = value.toString();
                var spUnitValue = value.split(" ");
                unitValue = spUnitValue.length > 1 ? spUnitValue[1] : "";
                value = spUnitValue[0];
              }

              if (
                updatedFormData[sectionIndex][
                  columnName.name + "_unit_" + count
                ]
              ) {
                updatedFormData[sectionIndex][
                  columnName.name + "_unit_" + count
                ] =
                  updatedFormData[sectionIndex][
                    columnName.name + "_unit_" + count
                  ];
              } else {
                updatedFormData[sectionIndex][
                  columnName.name + "_unit_" + count
                ] = unitValue;
              }
              if (updatedFormData[sectionIndex][fieldName]) {
                updatedFormData[sectionIndex][fieldName] =
                  updatedFormData[sectionIndex][fieldName];
              } else {
                updatedFormData[sectionIndex][fieldName] = value;
              }
            } else {
              if (isDuplicate && columnName.name === "sample_quantity") {
                updatedFormData[sectionIndex][fieldName] =
                  smlDetails["smpl_detail_smpl_pwd_qty"] +
                  " " +
                  smlDetails["smpl_detail_smpl_pwd_qty_unit"];
              } else {
                updatedFormData[sectionIndex][fieldName] = value;
              }
            }
          });
        });
        SPTableData.push(smlDetails);
      }
      count++;
    });
    return {
      SPTableData: SPTableData,
      updatedFormData: updatedFormData,
      count: count,
    };
  };

  const checkVerificationDataExists = (sp_lab_smplcode, isDuplicate) => {
    if (formData["0"].sv_detail && formData["0"].sv_detail.length > 0) {
      let count = 0;
      return formData["0"].sv_detail.filter((singleData, i) => {
        if (isDuplicate) {
          if (singleData.svd_smpllabcode === sp_lab_smplcode) {
            if (count === 1) {
              return true;
            }
            count++;
            return false;
          }
        }
        return singleData.svd_smpllabcode === sp_lab_smplcode;
      });
    }
    return [];
  };

  const InwardCondition = moduleType === "sampleinward";
  const IsVerification = false;
  const [actionName, setActionName] = useState("");

  const handleCloseInwardPopup = () => {
    setPopupOpenAssignment(false);
    setPopupIndex("");
    setEditableIndex("");
  };

  useEffect(() => {
    if (
      formData[1]?.[
        "smpl_detail_pkging_condition" +
          "_" +
          (actionName === "Save" ? editableIndex : tableData.length)
      ] &&
      formData[1]?.[
        "smpl_detail_pkging_condition" +
          "_" +
          (actionName === "Save" ? editableIndex : tableData.length)
      ] === "Unsealed"
    ) {
      const updatedFormData = { ...formData };
      updatedFormData[1][
        "smpl_detail_seal_number" +
          "_" +
          (actionName === "Save" ? editableIndex : tableData.length)
      ] = "NA";
      setFormData(updatedFormData);
    } else if (
      formData[1]?.[
        "smpl_detail_pkging_condition" +
          "_" +
          (actionName === "Save" ? editableIndex : tableData.length)
      ] &&
      formData[1]?.[
        "smpl_detail_pkging_condition" +
          "_" +
          (actionName === "Save" ? editableIndex : tableData.length)
      ] === "Sealed"
    ) {
      const updatedFormData = { ...formData };
      updatedFormData[1][
        "smpl_detail_seal_number" +
          "_" +
          (actionName === "Save" ? editableIndex : tableData.length)
      ] = "";
      setFormData(updatedFormData);
    }
  }, [
    formData[1]?.[
      "smpl_detail_pkging_condition" +
        "_" +
        (actionName === "Save" ? editableIndex : tableData.length)
    ],
  ]);

  const getCustomCellValues = (cell, rowIndex) => {
    if (cell.type === "doubleText") {
      cell.secondName = cell.name + "_unit_" + rowIndex;
    }
    if (moduleType === "sampleverification") {
      if (
        [
          "svd_stdsizeofsmpl_unit",
          "svd_abovesize_unit",
          "svd_belowsize_unit",
        ].includes(cell.name)
      ) {
        if (GetTenantDetails(1, 1) == "TPBPL") {
          cell.secondoptions = ["Ltr", "ml", "gm"];
        }
      }
    }
    return cell;
  };
  return (
    <div key={sectionIndex} className="row my-2 mx-0 bg-white">
      <Card className="Scrollable">
        {(simpleInwardId || pageType !== "inward") && (
          <CardBody>
            <CardTitle tag="h5">{section.title}</CardTitle>
            <CardSubtitle className="mb-2 text-muted" tag="h6">
              {/* Overview of the projects */}
            </CardSubtitle>
            <table className="table table-white responsive borderless no-wrap mt-3 align-middle renderTable">
              <thead>
                <tr>
                  <th>Sr. No.</th>
                  {section.headers.map(
                    (header, headerIndex) =>
                      header.name !== "smpl_detail_smpl_qty_unit" && (
                        <th key={"headerIndex" + headerIndex}>
                          {header.label}
                        </th>
                      )
                  )}
                  {action !== "View" ||
                  (moduleType === "sampleverification" && action === "View") ? (
                    <th>Action</th>
                  ) : null}
                </tr>
              </thead>
              <tbody>
                {tableData.map((singleTableData, rowIndex) =>
                  section.rows.map((row, rowIndex2) => (
                    <tr key={"rowIndex" + rowIndex} className="border-top">
                      <td>{rowIndex + 1}</td>
                      {row.map(
                        (cell, cellIndex) =>
                          cell.name !== "smpl_detail_smpl_qty_unit" && (
                            <td key={"cellIndex" + cellIndex}>
                              {(editableIndex === 0 && rowIndex === 0) ||
                              (editableIndex === rowIndex &&
                                !InwardCondition) ||
                              IsVerification
                                ? cell.name !== "smpl_detail_smpl_qty_unit" && (
                                    <span>
                                      <RenderFields
                                        field={getCustomCellValues(
                                          cell,
                                          rowIndex
                                        )}
                                        sectionIndex={sectionIndex}
                                        fieldIndex={rowIndex * 100 + cellIndex}
                                        formData={formData}
                                        handleFieldChange={handleFieldChange}
                                        formErrors={formErrors} // Pass formErrors to RenderFields
                                        ///for render table only
                                        renderTable={true}
                                        tableIndex={rowIndex}
                                        customName={cell.name + "_" + rowIndex}
                                        masterOptions={updatedMasterOptions}
                                        from="Table"
                                        handleFieldBlur={onSingleFieldChange}
                                      />
                                    </span>
                                  )
                                : Array.isArray(
                                    formData[sectionIndex][
                                      cell.name + "_" + rowIndex
                                    ]
                                  )
                                ? getSelectedOptionName(
                                    cell.options,
                                    masterOptions,
                                    cell.name,
                                    formData[sectionIndex][
                                      cell.name + "_" + rowIndex
                                    ],
                                    cell.name + "_" + rowIndex
                                  )
                                : cell.name == "smpl_detail_smpl_qty"
                                ? formData[sectionIndex][
                                    cell.name + "_" + rowIndex
                                  ] +
                                  " " +
                                  formData[sectionIndex][
                                    cell.name + "_unit" + "_" + rowIndex
                                  ] +
                                  (formData[sectionIndex][
                                    "smpl_detail_smpl_condtion_" + rowIndex
                                  ] === "Raw and Powdered Sample"
                                    ? "," +
                                      formData[sectionIndex][
                                        "smpl_detail_smpl_pwd_qty_" + rowIndex
                                      ] +
                                      " " +
                                      formData[sectionIndex][
                                        "smpl_detail_smpl_pwd_qty_unit" +
                                          "_" +
                                          rowIndex
                                      ]
                                    : "")
                                : formData["1"]?.["svd_id_" + rowIndex] ||
                                  [
                                    "sp_lab_smplcode",
                                    "sample_quantity",
                                  ].includes(cell.name)
                                ? [
                                    "svd_stdsizeofsmpl",
                                    "svd_abovesize",
                                    "svd_belowsize",
                                  ].includes(cell.name)
                                  ? formData[sectionIndex][
                                      cell.name + "_" + rowIndex
                                    ] +
                                    " " +
                                    formData[sectionIndex][
                                      cell.name + "_unit_" + rowIndex
                                    ]
                                  : formData[sectionIndex][
                                      cell.name + "_" + rowIndex
                                    ]
                                : InwardCondition
                                ? formData[sectionIndex][
                                    cell.name + "_" + rowIndex
                                  ]
                                : ""}
                            </td>
                          )
                      )}
                      <td>
                        <div className="actionColumn">
                          {action !== "View" ? (
                            <ActionOptionsTable
                              actions={
                                IsVerification
                                  ? VerificationSaveAction
                                  : (editableIndex === 0 && rowIndex === 0) ||
                                    editableIndex === rowIndex
                                  ? EditAction
                                  : editableIndex === 0 || editableIndex
                                  ? []
                                  : MainAction
                              }
                              onActionHandleClick={onActionHandleClick}
                              setPopupIndex={setPopupIndex}
                              useFor="Edit"
                              editableIndex={editableIndex}
                              popupIndex={popupIndex}
                              popupMessages={popupMessages}
                              saveClicked={saveClicked}
                              tableIndex={rowIndex}
                              isCustomSave={0}
                              setEditableIndex={setEditableIndex}
                              getInwardTabledata={getInwardTabledata}
                              simpleInwardId={simpleInwardId}
                              moduleType={moduleType}
                              setPopupOpenAssignment={setPopupOpenAssignment}
                              InwardCondition={InwardCondition}
                              setActionName={setActionName}
                              setIsViewOpen={setIsViewOpen}
                              isViewOpen={isViewOpen}
                              singleData={singleTableData}
                              setViewTableData={setViewTableData}
                            />
                          ) : moduleType === "sampleverification" &&
                            action === "View" ? (
                            <ActionOptionsTable
                              actions={[
                                {
                                  icon: "bi bi-eye",
                                  text: "View",
                                },
                              ]}
                              onActionHandleClick={onActionHandleClick}
                              setPopupIndex={setPopupIndex}
                              useFor="Edit"
                              editableIndex={editableIndex}
                              popupIndex={popupIndex}
                              popupMessages={popupMessages}
                              saveClicked={saveClicked}
                              tableIndex={rowIndex}
                              isCustomSave={0}
                              setEditableIndex={setEditableIndex}
                              getInwardTabledata={getInwardTabledata}
                              simpleInwardId={simpleInwardId}
                              moduleType={moduleType}
                              setPopupOpenAssignment={setPopupOpenAssignment}
                              InwardCondition={InwardCondition}
                              setActionName={setActionName}
                              setIsViewOpen={setIsViewOpen}
                              isViewOpen={isViewOpen}
                              singleData={singleTableData}
                              setViewTableData={setViewTableData}
                            />
                          ) : null}
                        </div>
                      </td>
                    </tr>
                  ))
                )}
                {isDisplayNewAddOption &&
                moduleType !== "sampleverification" &&
                action !== "View"
                  ? section.rows.map((row, rowIndex) => (
                      <tr key={"rowIndex" + rowIndex} className="border-top">
                        {!InwardCondition ? (
                          <>
                            <td>{rowIndex + 1 + tableData.length}</td>
                            {row.map(
                              (cell, cellIndex) =>
                                cell.name !== "smpl_detail_smpl_qty_unit" && (
                                  <td key={"cellIndex" + cellIndex}>
                                    <RenderFields
                                      field={cell}
                                      sectionIndex={sectionIndex}
                                      fieldIndex={rowIndex * 100 + cellIndex}
                                      formData={formData}
                                      handleFieldChange={handleFieldChange}
                                      formErrors={formErrors} // Pass formErrors to RenderFields
                                      ///for render table only
                                      renderTable={true}
                                      tableIndex={rowIndex}
                                      customName={
                                        cell.name + "_" + tableData.length
                                      }
                                      masterOptions={updatedMasterOptions}
                                      from="Table"
                                    />
                                  </td>
                                )
                            )}{" "}
                          </>
                        ) : (
                          <>
                            <td></td>
                            {row.map((cell, cellIndex) => (
                              <td></td>
                            ))}{" "}
                            <td>
                              {popupOpenAssignment && (
                                <ModalInward
                                  section={section}
                                  sectionIndex={sectionIndex}
                                  formData={formData}
                                  handleFieldChange={handleFieldChange}
                                  formErrors={formErrors}
                                  tableData={tableData}
                                  updatedMasterOptions={updatedMasterOptions}
                                  setPopupOpenAssignment={
                                    setPopupOpenAssignment
                                  }
                                  onActionHandleClick={onActionHandleClick}
                                  actionName={actionName}
                                  handleCloseInwardPopup={
                                    handleCloseInwardPopup
                                  }
                                  editableIndex={editableIndex}
                                  isBtnclicked={isBtnclicked}
                                  setIsOverlayLoader={setIsOverlayLoader}
                                  isOverlayLoader={isOverlayLoader}
                                />
                              )}
                              <div className="actionColumn">
                                <ActionOptionsTable
                                  actions={
                                    InwardCondition ? plusAction : saveAction
                                  }
                                  onActionHandleClick={onActionHandleClick}
                                  setPopupIndex={setPopupIndex}
                                  newCreation={1}
                                  popupMessages={popupMessages}
                                  saveClicked={saveClicked}
                                  isCustomSave={1}
                                  tableData={tableData}
                                  setEditableIndex={setEditableIndex}
                                  setPopupOpenAssignment={
                                    setPopupOpenAssignment
                                  }
                                  InwardCondition={InwardCondition}
                                  setActionName={setActionName}
                                />
                              </div>
                            </td>
                          </>
                        )}
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>
          </CardBody>
        )}
      </Card>
      {isViewOpen && (
        <SampleVerificationDetals
          setIsViewOpen={setIsViewOpen}
          viewTableData={viewTableData}
        />
      )}
    </div>
  );
};

RenderTableSection.propTypes = {
  section: PropTypes.string,
  sectionIndex: PropTypes.number,
  formData: PropTypes.object,
  handleFieldChange: PropTypes.func,
  addRow: PropTypes.func,
  deleteRow: PropTypes.func,
  deleteColumn: PropTypes.func,
  formErrors: PropTypes.object,
  setFormData: PropTypes.func,
  popupMessages: PropTypes.arrayOf(PropTypes.string),
  pageType: PropTypes.string,
  action: PropTypes.string,
  masterOptions: PropTypes.arrayOf(PropTypes.object),
  saveClicked: PropTypes.bool,
  setSaveClicked: PropTypes.func,
  setTableData: PropTypes.func,
  tableData: PropTypes.arrayOf(PropTypes.object),
  moduleType: PropTypes.string,
  setSimpaleInwardResponse: PropTypes.func,
  simpleInwardResponse: PropTypes.object,
  groupDefaultValue: PropTypes.any,
  testMemoId: PropTypes.string,
  getVerificationDetails: PropTypes.func,
  getSampleIdsMasterData: PropTypes.func,
  setIsOverlayLoader: PropTypes.func,
  isOverlayLoader: PropTypes.bool,
};

export default RenderTableSection;
