import React from "react";
import PropTypes from "prop-types";

const InputEmail = ({ field }) => {
  const {
    name,
    label,
    value,
    onChange,
    required,
    error,
    placeholder,
    readOnly,
    tooltip,
    characterLimit,
    actionClicked,
  } = field;
  return (
    <div className="form-group my-2">
      {label && (
        <label htmlFor={name} style={{ width: `${18}%` }}>
          {label}
          <span className="required_mark"> {required ? ` *` : null}</span>
        </label>
      )}
      <div className="w-75 d-inline-block mx-2">
        <input
          type="email"
          id={name}
          name={name}
          value={value}
          onChange={onChange}
          required={required}
          className="form-control rounded-2"
          placeholder={placeholder}
          readOnly={readOnly}
          title={tooltip}
          pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
          maxLength={characterLimit}
        />
        {error && actionClicked && <p className="text-danger">{error}</p>}
      </div>
    </div>
  );
};

InputEmail.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    required: PropTypes.bool,
    error: PropTypes.string,
    placeholder: PropTypes.string,
    readOnly: PropTypes.bool,
    tooltip: PropTypes.string,
    characterLimit: PropTypes.number,
    actionClicked: PropTypes.func,
  }).isRequired,
};
export default InputEmail;
