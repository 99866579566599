import Modal from "react-bootstrap/Modal";
import PropTypes from "prop-types";

function RejectPopupModal({
  isOpen,
  handleClose,
  handleConfirm,
  popupMessage,
  popupHeading,
  setRemarkText,
  remarkText,
}) {
  const onHandleCloseFunc=()=>{
    setRemarkText("")
    handleClose(false)
  }
  return (
    <span>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={isOpen}
        onHide={() => onHandleCloseFunc()}
      >
        <Modal.Body className="popup_body">
          <div className="popupSearchContainerBG">
            <div className="popupSearchContainer popupWidthAdjustment">
              <div className="rejectSearchCross">
                <button onClick={() => onHandleCloseFunc()} className="nonNativeButton2"   aria-label="Reject Button">
                  <i className="bi bi-x-lg h4"></i>
                </button>
              </div>

              <div className="rejectPopupHeader">
                <i className="bi bi-exclamation-lg rejectHeadingImg h1"></i>

                <h1 className="rejectHeadingText">{popupMessage}</h1>
              </div>
              <div className="popupSearchGroupContainer">
                <textarea
                  className="rejectRemarkTextbox"
              
                  placeholder="Enter Remark"
                  value={remarkText}
                  onChange={(e) => setRemarkText(e.target.value)}
                />
              </div>
              <div className="rejectButtonsContainer">
                <div className="popupSearchButtons">
                  <button type="button" onClick={() => onHandleCloseFunc()}>
                    Cancel
                  </button>
                  <button type="button" onClick={handleConfirm}>
                    Reject
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </span>
  );
}
RejectPopupModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  popupMessage: PropTypes.string,
  popupHeading: PropTypes.string,
  setRemarkText: PropTypes.func.isRequired,
  remarkText: PropTypes.string,
};
export default RejectPopupModal;
