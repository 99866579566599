import React, { useState } from "react";
import RenderFields from "../RenderFields";
import PropTypes from "prop-types";

import { Row } from "react-bootstrap";
import OverlayLoading from "../OverlayLoading";
import { checkSampleIdAvailibility } from "../commonHandlerFunction/sampleInwardHandlerFunctions";
import { GetTenantDetails } from "../../../services/commonServices";
const ModalInward = ({
  section,
  sectionIndex,
  formData,
  handleFieldChange,
  formErrors,
  tableData,
  updatedMasterOptions,
  setPopupOpenAssignment,
  onActionHandleClick,
  actionName,
  handleCloseInwardPopup,
  editableIndex,
  isBtnclicked,
  isOverlayLoader,
  setIsOverlayLoader,
}) => {
  const [isSampleIdAvailable, setIsSampleIdAvailable] = useState(
    actionName === "Save"
  );
  const InwardUnits = formData[1]?.jrf_commodity_detail?.cmd_unit || [];
  let unitoptions = [];
  InwardUnits.map((singleOpt) => {
    unitoptions.push({
      name: singleOpt.cu_name,
      id: singleOpt.cu_symbol,
    });
  });
  if (InwardUnits.length === 0) {
    unitoptions.push({
      name: "GM",
      id: "gm",
    });
  }
  const getCustomCellValues = (cell) => {
    if (cell.name == "smpl_detail_smpl_qty_unit") {
      cell.type = "text";
      if (
        formData[1]?.[
          "smpl_detail_smpl_condtion" +
            "_" +
            (actionName === "Save" ? editableIndex : tableData.length)
        ] === "Raw and Powdered Sample"
      ) {
        cell.label = "Raw Sample Quantity Unit";
        cell.placeholder = "Raw Sample Quantity Unit";
      } else {
        cell.label = "Sample Quantity Unit";
        cell.placeholder = "Sample Quantity Unit";
      }
    }
    if (cell.name == "smpl_detail_smpl_qty") {
      if (
        formData[1]?.[
          "smpl_detail_smpl_condtion" +
            "_" +
            (actionName === "Save" ? editableIndex : tableData.length)
        ] === "Raw and Powdered Sample"
      ) {
        // cell.label = "Raw Sample Quantity";
        cell.label = "Raw Approximate Quantity of Received Sample";
        cell.placeholder = "Raw Approximate Quantity of Received Sample";
      } else {
        // cell.label = "Sample Quantity";
        // cell.placeholder = "Enter Quantity of Received Sample";
        cell.label = "Approximate Quantity of Received Sample";
        cell.placeholder = "Enter Approximate Quantity of Received Sample";
      }
    } else if (cell.name === "sample_id") {
      // if (actionName === "Save") {
      //   cell.type = "label";
      // } else {
      //   cell.type = "text";
      // }
    } else if (cell.name == "smpl_detail_dos") {
      cell.viewOnly = true;
    } else if (cell.name === "smpl_detail_seal_number") {
      if (
        formData[1]?.[
          "smpl_detail_pkging_condition" +
            "_" +
            (actionName === "Save" ? editableIndex : tableData.length)
        ] === "Unsealed"
      ) {
        cell.required = false;
        cell.readOnly = true;
      } else {
        cell.required = true;
        cell.readOnly = false;
      }
    }
    else if(cell.name=="smpl_detail_smpl_condtion"){
      if(GetTenantDetails(1, 1) === "TPBPL"){
        cell.options=["Liquids","Semi Solid","Gaseous"]
      }
    }
    return cell;
  };
  const handleCustomFieldChange = (
    sectionIndex,
    fieldName,
    value,
    type = "",
    isChecked = ""
  ) => {
    let lastname =
      "_" + (actionName === "Save" ? editableIndex : tableData.length);
    let actualName = fieldName.split(lastname)[0];
    if (actualName === "sample_id") {
      checkSampleIdAvailibility(
        value,
        setIsOverlayLoader,
        setIsSampleIdAvailable
      );
    }
    handleFieldChange(sectionIndex, fieldName, value, type, isChecked);
  };
  const addExtraNewFields = (rowIndex) => {
    const cellFields = [
      {
        name: "smpl_detail_smpl_pwd_qty",
        width: 6,
        label: "Powdered Sample Quantity",
        type: "text",
        required: true,
        fieldWidth: "100",
        placeholder: "Enter Quantity of Powdered Received Sample",
      },
      {
        name: "smpl_detail_smpl_pwd_qty_unit",
        width: 6,
        label: "Powdered Sample Quantity Unit",
        placeholder: "Powdered Sample Quantity Unit",
        type: "text",
        required: true,
        fieldWidth: "100",
      },
    ];
    return cellFields.map((cell, cellIndex) => (
      <div
        className={"col-md-" + cell?.width}
        key={"Modal-Header-" + cellIndex}
      >
        <RenderFields
          field={getCustomCellValues(cell)}
          sectionIndex={sectionIndex}
          fieldIndex={rowIndex * 100 + cellIndex}
          formData={formData}
          handleFieldChange={handleCustomFieldChange}
          formErrors={formErrors} // Pass formErrors to RenderFields
          renderTable={true}
          tableIndex={rowIndex}
          customName={
            cell.name +
            "_" +
            (actionName === "Save" ? editableIndex : tableData.length)
          }
          masterOptions={updatedMasterOptions}
          upperClass="popupUpperClass"
        />
      </div>
    ));
  };
  return (
    <div className="popupSearchContainerBG">
      <div className="popupInwardModal popupWidthAdjustmentInward">
        <div className="rejectSearchCross">
          <button
            onClick={handleCloseInwardPopup}
            className="nonNativeButton2"
            aria-label="Reject Button"
          >
            <i className="bi bi-x-lg h4"></i>
          </button>
        </div>

        {section.rows.map((row, rowIndex) => (
          <>
            <Row className="autoWidthImportant">
              <h2 className="modalHeader">Sample Inward Details</h2>
              {isOverlayLoader && <OverlayLoading />}
              {section.headers.map((cell, cellIndex) => (
                <div
                  className={"col-md-" + cell?.width}
                  key={"Modal-Header-" + cellIndex}
                >
                  <RenderFields
                    field={getCustomCellValues(cell)}
                    sectionIndex={sectionIndex}
                    fieldIndex={rowIndex * 100 + cellIndex}
                    formData={formData}
                    handleFieldChange={handleCustomFieldChange}
                    formErrors={formErrors} // Pass formErrors to RenderFields
                    renderTable={true}
                    tableIndex={rowIndex}
                    customName={
                      cell.name +
                      "_" +
                      (actionName === "Save" ? editableIndex : tableData.length)
                    }
                    masterOptions={updatedMasterOptions}
                    upperClass="popupUpperClass"
                  />
                </div>
              ))}
              {formData[1]?.[
                "smpl_detail_smpl_condtion" +
                  "_" +
                  (actionName === "Save" ? editableIndex : tableData.length)
              ] === "Raw and Powdered Sample" && addExtraNewFields(rowIndex)}
            </Row>
          </>
        ))}
        <div className="popupInwardButtonsContainer">
          <div className="popupSearchButtons">
            <button type="button" onClick={handleCloseInwardPopup}>
              Cancel
            </button>
            <button
              type="button"
              disabled={!isSampleIdAvailable || isBtnclicked}
              onClick={() => {
                onActionHandleClick(actionName);
              }}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
ModalInward.propTypes = {
  section: PropTypes.object,
  sectionIndex: PropTypes.number,
  formData: PropTypes.object,
  handleFieldChange: PropTypes.func,
  formErrors: PropTypes.object,
  tableData: PropTypes.array,
  updatedMasterOptions: PropTypes.array,
  setPopupOpenAssignment: PropTypes.func,
  onActionHandleClick: PropTypes.func,
  actionName: PropTypes.string,
  handleCloseInwardPopup: PropTypes.func,
  editableIndex: PropTypes.number,
  isBtnclicked: PropTypes.bool,
  isOverlayLoader: PropTypes.bool,
  setIsOverlayLoader: PropTypes.func,
};
export default ModalInward;
