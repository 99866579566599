import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; // Import DatePicker styles
import moment from "moment"; // Import Moment.js for date formatting
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; // Import FontAwesome
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons"; // Import Calendar Icon
const InputDate = ({ field }) => {
  const {
    name,
    label,
    value,
    onChange,
    required,
    error,
    readOnly,
    tooltip,
    defaultValue,
    minDate,
    maxDate,
    fieldWidth,
    pastDate,
    pastdays,
    renderTable,
    actionClicked,
    upperClass,
    futureDays,
    startDate,
    noLimitation,
  } = field;
  const DISPLAY_DATE_FORMAT = "dd-MM-YYYY"; // Display format
  const INPUT_DATE_FORMAT = "YYYY-MM-DD"; // Format for internal data
  const [selectedDate, setSelectedDate] = useState(
    defaultValue ? moment(defaultValue, INPUT_DATE_FORMAT).toDate() : null
  );

  useEffect(() => {
    if (defaultValue) {
      setSelectedDate(moment(defaultValue, INPUT_DATE_FORMAT).toDate());
    }
  }, [defaultValue]);

  const [errorMsg, setErrorMsg] = useState(false);
  useEffect(() => {
    if ((value === undefined || value === "") && required) {
      setErrorMsg(true);
    } else {
      setErrorMsg(false);
    }
  }, [value, required]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    const formattedDate = moment(date).format(INPUT_DATE_FORMAT);
    onChange({
      target: {
        name,
        value: formattedDate, // Send formatted value back in YYYY-MM-DD format
      },
    });
  };

  // Calculate minDate
  const calculateMinDate = () => {
    if (minDate) {
      return moment(minDate, INPUT_DATE_FORMAT).toDate(); // Convert string to Date object
    }

    if (pastDate) {
      return pastdays
        ? new Date(new Date().setDate(new Date().getDate() - pastdays))
        : new Date(new Date().setMonth(new Date().getMonth() - 3));
    }
    if(noLimitation && pastdays){
      return new Date(new Date().setDate(new Date().getDate() - pastdays))
    }

    return startDate
      ? moment(startDate, INPUT_DATE_FORMAT).toDate()
      : new Date();
  };

  // Calculate maxDate
  const calculateMaxDate = () => {
    if (maxDate) {
      return moment(maxDate, INPUT_DATE_FORMAT).toDate(); // Convert string to Date object
    }

    if (pastDate && !noLimitation) {
      return new Date(); // Today's date for past dates
    }

    if (futureDays) {
      return new Date(new Date().setDate(new Date().getDate() + futureDays));
    }

    return null; // No max date
  };

  return (
    <div
      className={`form-group my-2 ${upperClass}`}
      style={{ position: "relative" }}
    >
      {label && (
        <label htmlFor={name} style={{ width: `${18}%` }}>
          {label}
          <span className="required_mark"> {required ? ` *` : null}</span>
        </label>
      )}
      <div className={`w-${fieldWidth ?? "75"} d-inline-block mx-2`}>
        <div style={{ position: "relative" }}>
          <DatePicker
            selected={selectedDate}
            onChange={handleDateChange}
            dateFormat={DISPLAY_DATE_FORMAT} // Custom date format for display
            placeholderText={"DD-MM-YYYY"}
            minDate={calculateMinDate()}
            maxDate={calculateMaxDate()}
            className="form-control rounded-2"
            readOnly={readOnly}
            onKeyDown={(e) => e.preventDefault()} // Prevent keyboard input
          />
          <FontAwesomeIcon
            icon={faCalendarAlt}
            style={{
              position: "absolute",
              top: "50%",
              right: "10px", // Position the icon on the right side
              transform: "translateY(-50%)",
              pointerEvents: "none", // Prevent the icon from blocking the input
            }}
          />
        </div>
        {errorMsg && actionClicked && !renderTable ? (
          <p className="text-danger errorMsg">{label} is required</p>
        ) : null}
        {error && actionClicked && <p className="text-danger">{error}</p>}
      </div>
    </div>
  );
};

InputDate.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.any, // Use `any` if the value can be of any type
    onChange: PropTypes.func,
    required: PropTypes.bool,
    error: PropTypes.string,
    readOnly: PropTypes.bool,
    tooltip: PropTypes.string,
    defaultValue: PropTypes.string,
    minDate: PropTypes.string,
    maxDate: PropTypes.string,
    fieldWidth: PropTypes.string,
    pastDate: PropTypes.bool,
    pastdays: PropTypes.number,
    renderTable: PropTypes.bool,
    actionClicked: PropTypes.func,
    upperClass: PropTypes.string,
    futureDays: PropTypes.number,
    startDate: PropTypes.string,
  }).isRequired,
};

export default InputDate;
