import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import PropTypes from "prop-types";
import OverlayLoading from "./OverlayLoading";
function DeleteConfirmation({
  isOpen,
  handleClose,
  handleConfirm,
  popupMessage,
  popupHeading,
  saveClicked,
  isOverlayLoader
}) {
  return (
    <span>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={isOpen}
        onHide={() => handleClose(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>{popupHeading}</Modal.Title>
        </Modal.Header>
        <Modal.Body><> {isOverlayLoader && <OverlayLoading />}{popupMessage}</></Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-primary"
            variant="primary"
            onClick={() => handleClose(false)}
          >
            No
          </Button>
          <Button className="btn btn-danger" onClick={handleConfirm} disabled={saveClicked}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </span>
  );
}
DeleteConfirmation.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  handleConfirm: PropTypes.func,
  popupMessage: PropTypes.string,
  popupHeading: PropTypes.string,
  saveClicked: PropTypes.bool,
  isOverlayLoader: PropTypes.bool
};
export default DeleteConfirmation;
