import React, { useEffect, useState } from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
import { ReactComponent as Trash } from "bootstrap-icons/icons/trash.svg";
import { sampleInwardDetailsGetAPI } from "../../services/api";

import { GetTenantDetails, postDataFromApi } from "../../services/commonServices";
import { getSelectedOptionName } from "../../services/commonFunction";
import PropTypes from "prop-types";

const RenderTableSection = ({
  section,
  sectionIndex,
  formData,
  deleteColumn,
  setFormData,
  pageType,
  masterOptions,
  isSubPreview,
  setSimpaleInwardResponse,
  simpleInwardResponse,
  tabIndex,
}) => {
  const [sampleDataTable, setSampleDataTable] = useState([]);
  const [simpleInwardId, setSimpleInwardId] = useState("");
  const [finalParamDataSort, setFinalParamDataSort] = useState([]);

  useEffect(() => {
    setSimpleInwardId(formData[1]?.sampleInwardIdMain);
    setTimeout(() => {
      getInwardTabledata(formData[1]?.sampleInwardIdMain);
      // }
    }, 1000);
  }, [formData[1]?.sampleInwardIdMain]);
  useEffect(() => {
    if (isSubPreview && simpleInwardResponse?.sample_set_data) {
      setSampleDataTable(simpleInwardResponse.sample_set_data);
    }
  }, [simpleInwardResponse]);

  const getInwardTabledata = async (simpleId) => {
    let payload = {
      smpl_inwrd_id: simpleId,
    };
    let res = await postDataFromApi(sampleInwardDetailsGetAPI, payload);
    if (res?.data?.status === 200) {
      const updatedFormData = { ...formData };
      let selectedSimpleIds = [];
      res.data.data.sample_set_data.forEach((singleInwardData, i) => {
        singleInwardData.smpl_set_smpljson.forEach((simpleId) => {
          selectedSimpleIds.push(simpleId);
        });

        if (!updatedFormData[sectionIndex]) {
          updatedFormData[sectionIndex] = {};
        }
        section.rows.forEach((row) => {
          row.forEach((columnName) => {
            const fieldName = `${columnName.name}_${i}`;
            const value = singleInwardData[columnName.name];
            updatedFormData[sectionIndex][fieldName] = value;
          });
        });
      });
      const newArray = res.data.data.sample_set_data;
      let FinalCombinedArray = [];
      newArray.map((singleParamaSet) => {
        let combinedArray = [];
        for (const [key, value] of Object.entries(
          singleParamaSet.smpl_set_groupjson
        )) {
          value.param_type = "Group"
          combinedArray.push({ paramType: "Group", ...value });
        }
        for (const [key, value] of Object.entries(
          singleParamaSet.smpl_set_paramjson
        )) {
          value.param_type = "Parameter"
          combinedArray.push({ paramType: "Parameter", ...value });
        }
        combinedArray = combinedArray.sort(
          (a, b) => a.sequanceNo - b.sequanceNo
        );
        FinalCombinedArray.push(combinedArray);
      });
      setFinalParamDataSort(FinalCombinedArray);
      setSampleDataTable(res.data.data.sample_set_data);

      setSimpleInwardId(res.data.data.smpl_inwrd_id);
      updatedFormData[0]["smpl_status"] = res.data.data.smpl_status;
      updatedFormData[0]["smpl_inwrd_No"] = res.data.data.smpl_inward_number;
      updatedFormData[0]["inward_msfm_number"] = res.data.data.inward_msfm_number;
      setFormData(updatedFormData);
    }
  };
  return (
    <div key={sectionIndex} className="row my-2 mx-0 bg-white">
      <Card className="Scrollable">
        {(simpleInwardId || pageType !== "inward") && sampleDataTable.length ? (
          <CardBody>
            <table className="table table-white responsive borderless no-wrap mt-3 align-middle renderTable previewRenderTable">
              {sampleDataTable.map((tablerow, rowIndex) =>
                section.rows.map((row, rowIndex2) => (
                  <React.Fragment key={"rowIndex2" + rowIndex2}>
                    {rowIndex === 0 && (
                      <thead>
                        <tr>
                          <td colSpan={row.length + 2}>
                            <CardTitle tag="h5" className="previewHeading">
                              Set {rowIndex + 1}
                            </CardTitle>
                          </td>
                        </tr>
                        <tr>
                          <th>Sr.</th>
                          {section.headers.map((header, headerIndex) => GetTenantDetails(1, 1) === "TPBPL" && header.label === "Basis" ? null : (
                            <>
                              {["Test Method"].includes(header.label) && GetTenantDetails(1, 1) !== "TPBPL" && (
                                <th key={"headerIndex" + headerIndex}>
                                  Parameter
                                </th>
                              )}
                              <th key={"headerIndex" + headerIndex}>
                                {GetTenantDetails(1, 1) === "TPBPL" ? (header.label === "Is Groups or Parameter" ? "Discipline Or Group" : header.label === "Groups / Parameters" ? "Parameter" : header.label) : header.label}
                                {!header.required && (
                                  <button
                                    className="trash_btn"
                                    onClick={() =>
                                      deleteColumn(sectionIndex, headerIndex)
                                    }
                                  >
                                    <Trash variant="text-danger" />
                                  </button>
                                )}
                              </th>
                            </>
                          ))}
                        </tr>
                      </thead>
                    )}
                    <tbody>
                      {rowIndex !== 0 && (
                        <tr>
                          <td colSpan={row.length + 2}>
                            <CardTitle tag="h5" className="previewHeading">
                              Set {rowIndex + 1}
                            </CardTitle>
                          </td>
                        </tr>
                      )}
                      <React.Fragment key={"rowIndex" + rowIndex}>
                        {/* {tablerow.smpl_set_paramjson.map(
                          (paramData, rowIndex) => {
                            let groupCount = 0;
                            tablerow.smpl_set_groupjson.map((singleGroup) => {
                              groupCount += singleGroup.parameters.length;
                            });
                            return section.rows.map((row, rowIndex2) => (
                              <tr
                                key={"rowIndex" + rowIndex}
                                className="border-top"
                              >
                                {rowIndex == 0 && (
                                  <React.Fragment>
                                    <td
                                      rowSpan={
                                        tablerow.smpl_set_paramjson.length +
                                        tablerow.smpl_set_groupjson.length +
                                        groupCount
                                      }
                                    >
                                      {rowIndex + 1}
                                    </td>
                                    <td
                                      rowSpan={
                                        tablerow.smpl_set_paramjson.length +
                                        tablerow.smpl_set_groupjson.length +
                                        groupCount
                                      }
                                    >
                                      {Array.isArray(
                                        tablerow["smpl_set_smpljson"]
                                      )
                                        ? getSelectedOptionName(
                                          [],
                                          masterOptions,
                                          "smpl_set_smpljson",
                                          tablerow["smpl_set_smpljson"],
                                          "smpl_set_smpljson"
                                        )
                                        : tablerow["smpl_set_smpljson"]}
                                    </td>
                                  </React.Fragment>
                                )}
                                <td>Parameter</td>
                                {row.map((cell, cellIndex) =>
                                  cell.subname != "group_id" &&
                                    cell.subname != "isGroup" ? (
                                    ["smpl_set_testmethodjson"].includes(
                                      cell.name
                                    ) ? (
                                      <>
                                        {GetTenantDetails(1, 1) !== "TPBPL" && <td>--</td>}
                                        <td key={"cellIndex" + cellIndex}>
                                          {Array.isArray(
                                            tablerow["smpl_set_smpljson"]
                                          ) && cell.subname == "basis"
                                            ? paramData[cell.subname].map(
                                              (basecode, index) =>
                                                (index != 0 ? "," : "") +
                                                basecode.basis_code
                                            )
                                            : paramData[cell.subname]}
                                        </td>
                                      </>
                                    ) : GetTenantDetails(1, 1) === "TPBPL" && cell.subname === "basis" ? null : (
                                      <td key={"cellIndex" + cellIndex}>
                                        {Array.isArray(
                                          tablerow["smpl_set_smpljson"]
                                        ) && cell.subname == "basis"
                                          ? paramData[cell.subname].map(
                                            (basecode, index) =>
                                              (index != 0 ? "," : "") +
                                              basecode.basis_code
                                          )
                                          : paramData[cell.subname]}
                                      </td>
                                    )
                                  ) : null
                                )}
                              </tr>
                            ));
                          }
                        )}
                        {tablerow.smpl_set_groupjson.map(
                          (paramData, rowIndex) => {
                            let groupCount = 0;
                            if (rowIndex == 0) {
                              tablerow.smpl_set_groupjson.map((singleGroup) => {
                                groupCount += singleGroup.parameters.length;
                              });
                            }

                            return section.rows.map((row, rowIndex2) => (
                              <>
                                <tr
                                  key={"rowIndex" + rowIndex}
                                  className="border-top"
                                >
                                  {rowIndex == 0 &&
                                    tablerow.smpl_set_paramjson.length == 0 && (
                                      <React.Fragment>
                                        <td
                                          rowSpan={
                                            tablerow.smpl_set_paramjson.length +
                                            tablerow.smpl_set_groupjson.length +
                                            groupCount
                                          }
                                        >
                                          {rowIndex + 1}
                                        </td>
                                        <td
                                          rowSpan={
                                            tablerow.smpl_set_paramjson.length +
                                            tablerow.smpl_set_groupjson.length +
                                            groupCount
                                          }
                                        >
                                          {Array.isArray(
                                            tablerow["smpl_set_smpljson"]
                                          )
                                            ? getSelectedOptionName(
                                              [],
                                              masterOptions,
                                              "smpl_set_smpljson",
                                              tablerow["smpl_set_smpljson"],
                                              "smpl_set_smpljson"
                                            )
                                            : tablerow["smpl_set_smpljson"]}
                                        </td>
                                      </React.Fragment>
                                    )}
                                  <td rowSpan={paramData.parameters.length + 1}>
                                    Group
                                  </td>
                                  <td rowSpan={paramData.parameters.length + 1}>
                                    {paramData["group_name"]}
                                  </td>
                                </tr>
                                {paramData.parameters.map((basecode, index) => (
                                  <tr>
                                    {row.map((cell, cellIndex) =>
                                      cell.subname != "group_id" &&
                                        cell.subname != "isGroup" &&
                                        cell.subname != "param_name" ? (
                                        ["smpl_set_testmethodjson"].includes(
                                          cell.name
                                        ) ? (
                                          <>
                                            <td>{basecode.param_name}</td>
                                            <td key={"cellIndex" + cellIndex}>
                                              {basecode.standards.map(
                                                (std, i) =>
                                                  (i != 0 ? "," : "") +
                                                  std.std_name
                                              )}
                                            </td>
                                          </>
                                        ) : (
                                          <td key={"cellIndex" + cellIndex}>
                                            {Array.isArray(
                                              tablerow["smpl_set_smpljson"]
                                            ) &&
                                              (cell.subname == "basis" ||
                                                cell.subname == "std_name")
                                              ? cell.subname == "basis"
                                                ? basecode.basis.map(
                                                  (base, i) =>
                                                    (i != 0 ? "," : "") +
                                                    base.basis_code
                                                )
                                                : 
                                                basecode.standards.map(
                                                  (std, i) =>
                                                    (i != 0 ? "," : "") +
                                                    std.std_name
                                                )
                                              : cell.subname == "param_name"
                                                ? paramData["group_name"]
                                                : paramData[cell.subname]}
                                          </td>
                                        )
                                      ) : null
                                    )}
                                  </tr>
                                ))}
                              </>
                            ));
                          }
                        )} */}
                        {
                  finalParamDataSort.map((singleSetData, setIndex) =>
                    singleSetData.map((paramData, setParamIndex) => {
                      let groupCount = 0;
                      sampleDataTable[setIndex].smpl_set_groupjson.map(
                        (singleGroup) => {
                          groupCount += singleGroup.parameters.length;
                        }
                      );
                      let mainRowsPan = 1;
                      mainRowsPan = sampleDataTable[setIndex].smpl_set_paramjson
                        .length +
                        sampleDataTable[setIndex].smpl_set_groupjson
                          .length +
                        groupCount

                      return section.rows.map((row, rowIndex2) => (
                        <>
                          <tr
                            key={"rowIndex" + rowIndex2}
                            className="border-top"
                          >
                            {setParamIndex == 0 && (
                              <React.Fragment>
                                <td
                                  rowSpan={
                                    mainRowsPan
                                  }
                                >
                                  {setIndex + 1}
                                </td>
                                <td
                                  rowSpan={
                                    mainRowsPan
                                  }
                                >
                                  {Array.isArray(
                                    sampleDataTable[setIndex]["smpl_set_smpljson"]
                                  )
                                    ? getSelectedOptionName(
                                      [],
                                      masterOptions,
                                      "smpl_set_smpljson",
                                      sampleDataTable[setIndex]["smpl_set_smpljson"],
                                      "smpl_set_smpljson"
                                    )
                                    : sampleDataTable[setIndex]["smpl_set_smpljson"]}
                                </td>
                              </React.Fragment>
                            )}
                            {
                              paramData.param_type === "Group" ? (
                                <>
                                  <td rowSpan={paramData.parameters.length + 1}>
                                    Group
                                  </td>
                                  <td rowSpan={paramData.parameters.length + 1}>
                                    {paramData["group_name"]}
                                  </td>
                                </>
                              ) : (
                                <>
                                  <td>
                                    Paramater
                                  </td>
                                  {
                                    row.map((cell, cellIndex) =>
                                      cell.subname != "group_id" &&
                                        cell.subname != "isGroup" ? (
                                        ["smpl_set_testmethodjson"].includes(
                                          cell.name
                                        ) ? (
                                          <>
                                            {GetTenantDetails(1, 1) !== "TPBPL" && <td>--</td>}
                                            <td key={"cellIndex" + cellIndex}>
                                              {cell.subname == "basis"
                                                ? paramData[cell.subname].map(
                                                  (basecode, index) =>
                                                    (index != 0 ? "," : "") +
                                                    basecode.basis_code
                                                )
                                                : paramData[cell.subname]}
                                            </td>
                                          </>
                                        ) :  GetTenantDetails(1, 1) === "TPBPL" && cell.subname === "basis" ? null :(
                                          <td key={"cellIndex" + cellIndex}>
                                            {cell.subname == "basis"
                                              ? paramData[cell.subname].map(
                                                (basecode, index) =>
                                                  (index != 0 ? "," : "") +
                                                  basecode.basis_code
                                              )
                                              : paramData[cell.subname]}
                                          </td>
                                        )
                                      ) : null
                                    )
                                  }
                                </>
                              )
                            }
                          </tr>
                          {paramData.param_type === "Group" && paramData.parameters.map((basecode, index) => (
                            <tr>
                              {row.map((cell, cellIndex) =>
                                cell.subname != "group_id" &&
                                  cell.subname != "isGroup" &&
                                  cell.subname != "param_name" ? (
                                  ["smpl_set_testmethodjson"].includes(
                                    cell.name
                                  ) ? (
                                    <>
                                      <td>{basecode.param_name}</td>
                                      <td key={"cellIndex" + cellIndex}>
                                        {basecode.standards.map(
                                          (std, i) =>
                                            (i != 0 ? "," : "") + std.std_name
                                        )}
                                      </td>
                                    </>
                                  ) : cell.name === "smpl_set_unit" ? <td>{basecode.param_unit}</td> : (
                                    <td key={"cellIndex" + cellIndex}>
                                      {
                                        (cell.subname == "basis" ||
                                          cell.subname == "std_name")
                                          ? cell.subname == "basis"
                                            ? basecode.basis.map(
                                              (base, i) =>
                                                (i != 0 ? "," : "") +
                                                base.basis_code
                                            )
                                            : // : basecode.standards.std_name
                                            basecode.standards.map(
                                              (std, i) =>
                                                (i != 0 ? "," : "") +
                                                std.std_name
                                            )
                                          : cell.subname == "param_name"
                                            ? paramData["group_name"]
                                            : paramData[cell.subname]}
                                    </td>
                                  )
                                ) : null
                              )}
                            </tr>
                          ))}
                        </>
                      ));
                    })
                  )
                }
                      </React.Fragment>
                  
                    </tbody>
                  </React.Fragment>
                ))
              )}
            </table>
          </CardBody>
        ) : null}
      </Card>
    </div>
  );
};

RenderTableSection.propTypes = {
  section: PropTypes.object,
  sectionIndex: PropTypes.number,
  tabIndex: PropTypes.number,
  formData: PropTypes.object,
  deleteColumn: PropTypes.func,
  setFormData: PropTypes.func,
  pageType: PropTypes.string,
  masterOptions: PropTypes.object,
  isSubPreview: PropTypes.bool,
  setSimpaleInwardResponse: PropTypes.func,
  simpleInwardResponse: PropTypes.object,
};

export default RenderTableSection;
