import React, { useEffect, useState } from "react";
import RenderFields from "./RenderFields";
import { Card, CardBody, CardSubtitle, CardTitle } from "reactstrap";

import {
  getsamplelabcodeApi,
  sampleInwardDetailsGetAPI,
  testReportDetailsApi,
} from "../../services/api";

import { postDataFromApi } from "../../services/commonServices";
import ActionOptionsTable from "./ActionOptionsTable";
import { getSelectedOptionName } from "../../services/commonFunction";
import { assignmentPageHandleAction } from "./commonHandlerFunction/GroupAssignmentFunctions";
import { InwardPageHandleAction } from "./commonHandlerFunction/sampleInwardHandlerFunctions";
import { sampleVerificationHandler } from "./commonHandlerFunction/sampleVerificationHandlerFunctions";
import PropTypes from "prop-types";
import ModalInward from "./commonModalForms/modalInward";
import SampleVerificationDetals from "./commonModalForms/SampleVerificationDetals";
import { toast } from "react-toastify";
const RenderTableSetAllManualSection = ({
  section,
  sectionIndex,
  formData,
  handleFieldChange,
  addRow,
  deleteRow,
  deleteColumn,
  formErrors,
  setFormData,
  popupMessages,
  pageType,
  action,
  masterOptions,
  saveClicked,
  setSaveClicked,
  setTableData,
  tableData,
  moduleType,
  setSimpaleInwardResponse,
  simpleInwardResponse,
  groupDefaultValue,
  testMemoId,
  getVerificationDetails,
  getSampleIdsMasterData,
  setIsOverlayLoader,
  isOverlayLoader,
  viewOnly
}) => {
  const [popupIndex, setPopupIndex] = useState(-1);
  const [isDisplayNewAddOption, setIsDisplayNewAddOption] = useState(true);

  const [popupOpenAssignment, setPopupOpenAssignment] = useState(false);
  const [isBtnclicked, setIsBtnClicked] = useState(false);
  const [sampleDetails, setSampleDetails] = useState([])
  const [isViewOpen, setIsViewOpen] = useState(false)
  const [viewTableData, setViewTableData] = useState([])

  let EditAction = [
    {
      icon: "bi bi-floppy-fill",
      text: "Save",
    },
    // {
    //   icon: "bi bi-trash3-fill",
    //   text: "Delete",
    // },
    {
      icon: "bi bi-x-circle-fill",
      text: "Cancel",
    },
  ];
  let MainAction = [
    {
      icon: "bi bi-pencil-square",
      text: "Edit",
    },
    {
      icon: "bi bi-trash3-fill",
      text: "Delete",
    },
  ];
  let saveAction = [
    {
      icon: "bi bi-floppy-fill",
      text: "Save",
    },
  ];
  let plusAction = [
    {
      icon: "bi bi-plus-circle-fill",
      text: "Save",
    },
  ];

  if (pageType === "assignment") {
    MainAction.splice(0, 1);
  }

  if (moduleType === "sampleverification") {
    MainAction.splice(1, 1);
    MainAction.push({
      icon: "bi bi-eye",
      text: "View",
    },);
  }

  const [simpleInwardId, setSimpleInwardId] = useState("");
  const [editableIndex, setEditableIndex] = useState("");
  const [updatedMasterOptions, setUpdatedMasterOptions] = useState([]);
  const [actualMasterOptions, setActualMasterOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const onSingleFieldChange = (
    sectionIndex,
    fieldName,
    value,
    type = "",
    isChecked = ""
  ) => {
    handleFieldChange(sectionIndex, fieldName, value, type, isChecked);
  };

  const onActionHandleClick = async (actionSelected) => {
    if (actionSelected === "Save" || actionSelected === "customSave") {
      setIsBtnClicked(true);
      let newRowIndex = editableIndex;
      if (actionSelected === "customSave") {
        newRowIndex = tableData.length;
      }
      let payload = {
        "param_name": formData["1"]?.["param_name_" + newRowIndex],
        "param_value": formData["1"]?.["param_value_" + newRowIndex],
        "param_unit": formData["1"]?.["param_unit_" + newRowIndex],
        "param_method": formData["1"]?.["param_method_" + newRowIndex],
      }
      let nonRequiredFields = [];

      for (let obj in payload) {
        if (
          (payload[obj] === undefined ||
            payload[obj] === "") &&
          !nonRequiredFields.includes(obj)
        ) {
          const field = section.rows[0].filter((field, index) => {
            if (field.name === obj) {
              field.label = section.headers[index].label;
              return true;
            }
            return false;
          });

          if (field.length > 0) {
            let errLabel = field ? field[0].label : "";
            toast.error(errLabel + " is required", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            setIsBtnClicked(false);
            return;
          }

        }
      }
      let ExistsData = tableData;

      if (actionSelected === "Save") {
        ExistsData[newRowIndex] = payload
      }
      else {
        ExistsData.push(payload)
      }
      setTableData(ExistsData)
      setPopupOpenAssignment(false);
      setPopupIndex("");
      setEditableIndex("");
      setIsBtnClicked(false);
      setIsOverlayLoader(false)
      setSaveClicked(false);
    } else if (actionSelected === "Delete") {
      let newRowIndex = popupIndex;
      let ExistsData = tableData;
      ExistsData.splice(newRowIndex, 1)
      setTableData(ExistsData)
      setPopupOpenAssignment(false);
      setPopupIndex("");
      setEditableIndex("");
      setIsBtnClicked(false);
      setIsOverlayLoader(false)
      setSaveClicked(false);
    } else if (actionSelected === "Cancel") {
      setEditableIndex("");
    }
  };
  const [actionName, setActionName] = useState("");

  const handleCloseInwardPopup = () => {
    setPopupOpenAssignment(false);
    setPopupIndex("");
    setEditableIndex("");
  };


  const getCustomCellValues = (cell, rowIndex) => {
    if (cell.type === "doubleText") {
      cell.secondName = cell.name + "_unit_" + rowIndex;
    }
    return cell;
  };
  return (
    <div key={sectionIndex} className="row my-2 mx-0 bg-white">

      <Card className="Scrollable">
        {(simpleInwardId || pageType !== "inward") && (
          <CardBody>
            <CardTitle tag="h5">{section.title}</CardTitle>
            <CardSubtitle className="mb-2 text-muted" tag="h6">
              {/* Overview of the projects */}
            </CardSubtitle>
            <table className="table table-white responsive borderless no-wrap mt-3 align-middle renderTable">
              <thead>
                <tr>
                  <th>Sr. No.</th>
                  {section.headers.map(
                    (header, headerIndex) =>
                      header.name !== "smpl_detail_smpl_qty_unit" && (
                        <th key={"headerIndex" + headerIndex}>
                          {header.label}
                        </th>
                      )
                  )}
                  {action.toLowerCase() !== "view" && !viewOnly ? <th>Action</th> : null}
                </tr>
              </thead>
              <tbody>
                {tableData.map((singleTableData, rowIndex) =>
                  section.rows.map((row, rowIndex2) => (
                    <tr key={"rowIndex" + rowIndex} className="border-top">
                      <td>{rowIndex + 1}</td>
                      {row.map(
                        (cell, cellIndex) =>
                          cell.name !== "smpl_detail_smpl_qty_unit" && (
                            <td key={"cellIndex" + cellIndex}>
                              {(editableIndex === 0 && rowIndex === 0) ||
                                (editableIndex === rowIndex)
                                ? cell.name !== "smpl_detail_smpl_qty_unit" && (
                                  <span>
                                    <RenderFields
                                      field={getCustomCellValues(
                                        cell,
                                        rowIndex
                                      )}
                                      sectionIndex={sectionIndex}
                                      fieldIndex={rowIndex * 100 + cellIndex}
                                      formData={formData}
                                      handleFieldChange={handleFieldChange}
                                      formErrors={formErrors} // Pass formErrors to RenderFields
                                      ///for render table only
                                      renderTable={true}
                                      tableIndex={rowIndex}
                                      customName={cell.name + "_" + rowIndex}
                                      masterOptions={updatedMasterOptions}
                                      from="Table"
                                      handleFieldBlur={onSingleFieldChange}
                                    />
                                  </span>
                                )
                                : Array.isArray(
                                  formData[sectionIndex][
                                  cell.name + "_" + rowIndex
                                  ]
                                )
                                  ? getSelectedOptionName(
                                    cell.options,
                                    masterOptions,
                                    cell.name,
                                    formData[sectionIndex][
                                    cell.name + "_" + rowIndex
                                    ],
                                    cell.name + "_" + rowIndex
                                  )
                                  : cell.name == "smpl_detail_smpl_qty"
                                    ? formData[sectionIndex][
                                    cell.name + "_" + rowIndex
                                    ] +
                                    " " +
                                    formData[sectionIndex][
                                    cell.name + "_unit" + "_" + rowIndex
                                    ]
                                    : formData["1"]?.["svd_id_" + rowIndex] ||
                                      [
                                        "sp_lab_smplcode",
                                        "sample_quantity",
                                      ].includes(cell.name)
                                      ? [
                                        "svd_stdsizeofsmpl",
                                        "svd_abovesize",
                                        "svd_belowsize",
                                      ].includes(cell.name)
                                        ? formData[sectionIndex][
                                        cell.name + "_" + rowIndex
                                        ] +
                                        " " +
                                        formData[sectionIndex][
                                        cell.name + "_unit_" + rowIndex
                                        ]
                                        : formData[sectionIndex][
                                        cell.name + "_" + rowIndex
                                        ]
                                      : formData[sectionIndex][
                                      cell.name + "_" + rowIndex
                                      ]}
                            </td>
                          )
                      )}
                      <td>
                        <div className="actionColumn">
                          {action.toLowerCase() !== "view" && !viewOnly ? (
                            <ActionOptionsTable
                              actions={
                                (editableIndex === 0 && rowIndex === 0) ||
                                  editableIndex === rowIndex
                                  ? EditAction
                                  : MainAction
                              }
                              onActionHandleClick={onActionHandleClick}
                              setPopupIndex={setPopupIndex}
                              useFor="Edit"
                              editableIndex={editableIndex}
                              popupIndex={popupIndex}
                              popupMessages={popupMessages}
                              saveClicked={saveClicked}
                              tableIndex={rowIndex}
                              isCustomSave={0}
                              setEditableIndex={setEditableIndex}
                              simpleInwardId={simpleInwardId}
                              moduleType={moduleType}
                              setPopupOpenAssignment={setPopupOpenAssignment}
                              setActionName={setActionName}
                              setIsViewOpen={setIsViewOpen}
                              isViewOpen={isViewOpen}
                              singleData={singleTableData}
                              setViewTableData={setViewTableData}
                            />
                          ) : null}
                        </div>
                      </td>
                    </tr>
                  ))
                )}
                {isDisplayNewAddOption &&
                  moduleType !== "sampleverification" &&
                  action.toLowerCase() !== "view" && !viewOnly
                  ? section.rows.map((row, rowIndex) => (
                    <tr key={"rowIndex" + rowIndex} className="border-top">
                      <>
                        <td>{rowIndex + 1 + tableData.length}</td>
                        {row.map(
                          (cell, cellIndex) =>
                            cell.name !== "smpl_detail_smpl_qty_unit" && (
                              <td key={"cellIndex" + cellIndex}>
                                <RenderFields
                                  field={cell}
                                  sectionIndex={sectionIndex}
                                  fieldIndex={rowIndex * 100 + cellIndex}
                                  formData={formData}
                                  handleFieldChange={handleFieldChange}
                                  formErrors={formErrors} // Pass formErrors to RenderFields
                                  ///for render table only
                                  renderTable={true}
                                  tableIndex={rowIndex}
                                  customName={
                                    cell.name + "_" + tableData.length
                                  }
                                  masterOptions={updatedMasterOptions}
                                  from="Table"
                                />
                              </td>
                            )
                        )}{" "}
                        <td>
                          <div className="actionColumn">
                            <ActionOptionsTable
                              actions={
                                saveAction
                              }
                              onActionHandleClick={onActionHandleClick}
                              setPopupIndex={setPopupIndex}
                              newCreation={1}
                              popupMessages={popupMessages}
                              saveClicked={saveClicked}
                              isCustomSave={1}
                              tableData={tableData}
                              setEditableIndex={setEditableIndex}
                              setPopupOpenAssignment={
                                setPopupOpenAssignment
                              }
                              setActionName={setActionName}
                            />
                          </div>
                        </td>
                      </>
                    </tr>
                  ))
                  : null}
              </tbody>
            </table>
          </CardBody>
        )}
      </Card>
      {isViewOpen && <SampleVerificationDetals setIsViewOpen={setIsViewOpen} viewTableData={viewTableData} />}
    </div>
  );
};

RenderTableSetAllManualSection.propTypes = {
  section: PropTypes.string,
  sectionIndex: PropTypes.number,
  formData: PropTypes.object,
  handleFieldChange: PropTypes.func,
  addRow: PropTypes.func,
  deleteRow: PropTypes.func,
  deleteColumn: PropTypes.func,
  formErrors: PropTypes.object,
  setFormData: PropTypes.func,
  popupMessages: PropTypes.arrayOf(PropTypes.string),
  pageType: PropTypes.string,
  action: PropTypes.string,
  masterOptions: PropTypes.arrayOf(PropTypes.object),
  saveClicked: PropTypes.bool,
  setSaveClicked: PropTypes.func,
  setTableData: PropTypes.func,
  tableData: PropTypes.arrayOf(PropTypes.object),
  moduleType: PropTypes.string,
  setSimpaleInwardResponse: PropTypes.func,
  simpleInwardResponse: PropTypes.object,
  groupDefaultValue: PropTypes.any,
  testMemoId: PropTypes.string,
  getVerificationDetails: PropTypes.func,
  getSampleIdsMasterData: PropTypes.func,
  setIsOverlayLoader: PropTypes.func,
  isOverlayLoader: PropTypes.bool
};


export default RenderTableSetAllManualSection;
