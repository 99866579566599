import { toast } from "react-toastify";
import {
  sampleverificationUpdateApi,
  sampleverificationdetailCreateApi,
  sampleverificationdetailDeleteApi,
  sampleverificationdetailUpdateApi,
} from "../../../services/api";
import {
  deleteDataFromApi,
  GetTenantDetails,
  postDataFromApi,
  putDataFromApi,
} from "../../../services/commonServices";
import { handleSFMCreate } from "./sfmHandlerFunctions";

export const sampleVerificationHandler = async (
  actionSelected,
  editableIndex,
  tableData,
  testMemoId,
  formData,
  section,
  setSaveClicked,
  setPopupIndex,
  setEditableIndex,
  popupIndex,
  newpopupIndex = "",
  setIsOverlayLoader
) => {
  if (actionSelected === "Save" || actionSelected === "customSave") {
    let newRowIndex =
      editableIndex === 0 || editableIndex ? editableIndex : tableData.length;
    if (actionSelected === "customSave") {
      newRowIndex = tableData.length;
    }
    let payload = {
      sv_id: formData[0].sv_id,
      sample_verification_detail: {
        svd_smpllabcode: formData["1"]?.["sp_lab_smplcode_" + newRowIndex],
        svd_smplweight: formData["1"]?.["sample_quantity_" + newRowIndex],
        svd_stdsizeofsmpl:
          formData["1"]?.["svd_stdsizeofsmpl_" + newRowIndex] +
          " " +
          (formData["1"]?.["svd_stdsizeofsmpl_unit_" + newRowIndex]
            ? formData["1"]?.["svd_stdsizeofsmpl_unit_" + newRowIndex]
            : "mm"),
        svd_abovesize:
          formData["1"]?.["svd_abovesize_" + newRowIndex] +
          " " +
          (formData["1"]?.["svd_abovesize_unit_" + newRowIndex]
            ? formData["1"]?.["svd_abovesize_unit_" + newRowIndex]
            : "%"),
        svd_belowsize:
          formData["1"]?.["svd_belowsize_" + newRowIndex] +
          " " +
          (formData["1"]?.["svd_belowsize_unit_" + newRowIndex]
            ? formData["1"]?.["svd_belowsize_unit_" + newRowIndex]
            : "%"),
        svd_remark: formData["1"]?.["svd_remark_" + newRowIndex],
        tenant:GetTenantDetails(1)
      },
    };
    let res;
    for (let obj in payload.sample_verification_detail) {
      if (
        payload["sample_verification_detail"][obj] === undefined ||
        !payload["sample_verification_detail"][obj]
      ) {
        const field = section.rows[0].filter((field, index) => {
          if (field.name === obj) {
            field.label = section.headers[index].label;
            return true;
          }
          return false;
        });
        let errLabel = field.length ? field[0].label : obj;
        toast.error(errLabel + "  required", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return;
        // }
      }
      else if (['svd_stdsizeofsmpl', 'svd_abovesize', 'svd_belowsize'].includes(obj)) {
        let spName = payload["sample_verification_detail"][obj].split(" ");
        if (spName[0] === "") {
          const field = section.rows[0].filter((field, index) => {
            if (field.name === obj) {
              field.label = section.headers[index].label;
              return true;
            }
            return false;
          });
          let errLabel = field.length ? field[0].label : obj;
          toast.error(errLabel + "  required", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          return;
        }
      }
    }
    setIsOverlayLoader(true)
    if (formData["1"]?.["svd_id_" + newRowIndex]) {
      payload.svd_id = formData["1"]?.["svd_id_" + newRowIndex];
      res = await putDataFromApi(sampleverificationdetailUpdateApi, payload);
    } else {
      res = await postDataFromApi(sampleverificationdetailCreateApi, payload);
    }
    if (res?.data && res.data.status === 200) {
      setPopupIndex("");
      setEditableIndex("");
      toast.success(res.data.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      toast.error(res.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    setIsOverlayLoader(false)
  } else if (actionSelected === "Edit") {
    setEditableIndex(newpopupIndex || popupIndex);
  } else if (actionSelected === "Delete") {
    setSaveClicked(true);
    let payload = {
      svd_id: tableData[popupIndex]?.svd_id,
    };
    let res = await deleteDataFromApi(
      sampleverificationdetailDeleteApi,
      payload
    );
    if (res.data && res.data.status === 200) {
      toast.success(res.data.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      toast.error(res.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    setSaveClicked(false);
  } else if (actionSelected === "Cancel") {
    setEditableIndex("");
  }
};

export const handleVerificationFormPost = async (
  e,
  handleSubmit,
  setJRFCreationType,
  setIsPopupOpen
) => {
  e.preventDefault();
  setJRFCreationType("post");
  setIsPopupOpen(true);
};

export const handleVerificationMain = async (
  formData,
  navigate,
  testMemoId,
  setSaveClicked,
  setIsOverlayLoader
) => {
  let payload = {
    sv_id: formData[0].sv_id,
    sample_verification: {
      status: "verified",
      tenant:GetTenantDetails(1)
    },
  };
  setIsOverlayLoader(true)
  let res = await putDataFromApi(sampleverificationUpdateApi, payload);
  if (res?.data?.status === 200) {
    setSaveClicked(true)
    handleSFMCreate(res.data.data, navigate, res.data.message, testMemoId,setSaveClicked);
  } else {
    setSaveClicked(false)
    toast.error(res.message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
  setIsOverlayLoader(false)
};
